import React, { useState } from 'react';
import { Box, Image, Text, Stack, Link, Flex, Button } from '@chakra-ui/react';

const ArtistCard = ({ name, specialty, imageUrl, socials }) => {
  // State to toggle the visibility of the socials
  const [showSocials, setShowSocials] = useState(false);

  // Toggle function to handle showing or hiding the socials
  const toggleSocials = () => setShowSocials(!showSocials);

  return (
    <Flex
      direction="column"
      align="center"
      p={{ base: '4', md: '6' }}
      bg="white"
      boxShadow="lg"
      borderRadius="lg"
      transition="transform 0.3s ease, box-shadow 0.3s ease"
      _hover={{ transform: 'scale(1.03)', boxShadow: '2xl' }}
      maxW={{ base: '100%', md: '720px' }}
      mb="6"
    >
      {/* Set fixed dimensions and make the image rounded */}
      <Image
        src={imageUrl}
        alt={name}
        borderRadius="5%"
        height="100%"
        width="254px"
        objectFit="cover"
        mb="4"
      />
      <Stack spacing={3} textAlign="center">
        <Text fontWeight="bold" fontSize={{ base: 'xl', md: '2xl' }}>{name}</Text>
        <Text color="gray.500" fontSize={{ base: 'md', md: 'lg' }}>{specialty}</Text>

        {/* Toggle Button */}
        <Button
          size="sm"
          mt="4"
          onClick={toggleSocials}
          colorScheme={showSocials ? 'red' : 'teal'}
        >
          {showSocials ? 'Hide' : 'Contacts'}
        </Button>

        {/* Conditionally render the socials based on showSocials state */}
        {showSocials && socials && (
          <Flex direction="column" mt="2" align="center">
            <Text fontSize="sm" color="gray.600" mb="2">Reach {name}:</Text>
            {socials.tiktok && (
              <Link href={`https://www.tiktok.com/${socials.tiktok}`} isExternal color="blue.500" fontSize="md">
                TikTok: {socials.tiktok}
              </Link>
            )}
            {socials.instagram && (
              <Link href={`https://www.instagram.com/${socials.instagram}`} isExternal color="purple.500" fontSize="md">
                Instagram: {socials.instagram}
              </Link>
            )}
            {socials.phone && <Text fontSize="md" mt="2">Phone: {socials.phone}</Text>}
          </Flex>
        )}
      </Stack>
    </Flex>
  );
};

export default ArtistCard;
