import React, { useState } from 'react';
import QRCode from 'qrcode';
import { getZapEndpoint, makeZapRequest } from '../../../utils/zapUtils.js';
import Modal from './Modal'; // Import the modal component
import './Donate.css'; // Assuming you're adding styles in a separate file

const Donate = () => {
  const hardcodedLud16 = 'fiatjaf@walletofsatoshi.com';
  const [zapAmount, setZapAmount] = useState(1); // Default to 1 satoshi
  const [zapComment, setZapComment] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('');
  const [qrCodeDataUrl, setQrCodeDataUrl] = useState('');
  const [invoiceToCopy, setInvoiceToCopy] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false); // State for controlling the modal

  const handleClipboardCopy = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(invoiceToCopy).then(() => {
        alert('Invoice copied to clipboard!');
      }).catch(err => {
        alert('Failed to copy invoice to clipboard. Please try again.');
        console.error('Failed to copy:', err);
      });
    }
  };

  const generateQRCode = async (invoice) => {
    try {
      const qrCodeUrl = await QRCode.toDataURL(invoice);
      setQrCodeDataUrl(qrCodeUrl);
      setIsModalOpen(true); // Open the modal when QR code is generated
    } catch (err) {
      console.error('Error generating QR code:', err);
      alert('Failed to generate QR code.');
    }
  };

  const handleZap = async () => {
    const amountInMillisats = zapAmount * 1000; // Convert sats to millisats

    const metadata = {
      content: JSON.stringify({ lud16: hardcodedLud16, comment: zapComment })
    };

    try {
      const callback = await getZapEndpoint(metadata);

      if (callback) {
        if (!amountInMillisats) {
          alert('Please enter a zap amount.');
          return;
        }

        const zapRequest = makeZapRequest({
          profile: hardcodedLud16,
          event: null,
          amount: amountInMillisats,
          comment: zapComment,
          relays: ['wss://relay.damus.io']
        });

        const response = await fetch(`${callback}?amount=${amountInMillisats}`);
        const { pr: invoice } = await response.json();
        console.log('Invoice:', invoice);

        setInvoiceToCopy(invoice); // Set invoice for copying

        // WebLN payment handling
        if (window.webln) {
          try {
            await window.webln.enable();
            await window.webln.sendPayment(invoice);
            setPaymentStatus('Payment sent successfully via WebLN!');
          } catch (err) {
            console.error('WebLN payment error:', err);
            setPaymentStatus('Failed to send payment via WebLN.');
            generateQRCode(invoice); // Generate QR code as fallback
          }
        } else {
          generateQRCode(invoice); // Generate QR code as fallback
        }
      } else {
        alert('Failed to retrieve zap endpoint.');
      }
    } catch (err) {
      console.error('Zap error:', err);
      alert('An unexpected error occurred. Please try again later.');
    }
  };

  return (
    <div className="donate-container">
      <h2 className="donate-heading">Donate directly to Mantra Tattoos</h2>

      {/* Zap amount input */}
      <div className="input-container">
        <label className="input-label">
          Zap Amount (in sats):
          <input
            className="input-field"
            type="number"
            value={zapAmount}
            onChange={(e) => setZapAmount(Number(e.target.value))}
            min="1"
            placeholder="Enter amount in sats"
          />
        </label>
      </div>

      {/* Comment input */}
      <div className="input-container">
        <label className="input-label">
          Comment:
          <input
            className="input-field"
            type="text"
            value={zapComment}
            onChange={(e) => setZapComment(e.target.value)}
            placeholder="Optional comment"
          />
        </label>
      </div>

      {/* Send Zap button */}
      <button className="donate-btn" onClick={handleZap}>Send Zap</button>

      {/* Display payment status */}
      {paymentStatus && <p className="payment-status">{paymentStatus}</p>}

      {/* Modal for QR code and copy functionality */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)} // Close modal when clicking "Close"
        qrCodeDataUrl={qrCodeDataUrl}
        onCopy={handleClipboardCopy} // Handle invoice copy
      />
    </div>
  );
};

export default Donate;
