import React, { useEffect, useState } from 'react';
import { VStack, Input, Button, Text, Box, Image, Flex, Divider } from '@chakra-ui/react';
import { SimplePool } from 'nostr-tools/pool';

const GEYSERELAY = [
  "wss://nostr.wine",
  "wss://relay.damus.io",
  "wss://relay.highlighter.com",
  "wss://relay.nostrocket.org",
  "wss://relay.primal.net",
  "wss://relay.nostr.band",
  "wss://relay.current.fyi",
  "wss://purplepag.es",
  "wss://nos.lol",
  "wss://pyramid.fiatjaf.com",
  "wss://nostr.plebchain.org",
  "wss://relay.nostr.info/",
  "wss://relay.f7z.io/",
  "wss://nostr.fmt.wiz.biz",
  "wss://atlas.nostr.land",
  "wss://lightningrelay.com",
  "wss://nostr.oxtr.dev",
  "wss://nostr-pub.semisol.dev",
  "wss://brb.io",
  "wss://nostr.bitcoiner.social",
  "wss://relay.nostr.bg",
  "wss://nostr-01.bolt.observer",
  "wss://nostr.mom",
  "wss://relay.snort.social",
  "wss://puravida.nostr.land",
  "wss://relay.thes.ai",
  "wss://nostr.lu.ke",
  "wss://offchain.pub",
  "wss://eden.nostr.land",
  "wss://nostr-pub.wellorder.net/",
  "wss://140.f7z.io",
  "wss://nos.lol/",
  "wss://relay.mostr.pub/",
  "wss://relay.snort.social/"
];

export const Tattoostr = () => {
  const [pool, setPool] = useState(null);
  const [events, setEvents] = useState([]);
  const [metadata, setMetadata] = useState({});
  const [inputTag, setInputTag] = useState(''); // Initialize inputTag state

  useEffect(() => {
    const _pool = new SimplePool();
    setPool(_pool);
  }, []);

  useEffect(() => {
    if (pool) {
      const subEvents = pool.subscribeMany(GEYSERELAY, [
        {
          kinds: [1],
          limit: 100,
          '#t': ['tattoostr'],
        },
      ], {
        onevent(event) {
          setEvents((prevEvents) => [...prevEvents, event]);
        },
      });

      return () => {
        subEvents.close(); // Use close() to unsubscribe
      };
    } else {
      setEvents([]);
      setMetadata({});
    }
  }, [pool]);

  useEffect(() => {
    if (events.length > 0 && pool) {
      const pubkeys2Fetch = events.map((event) => event.pubkey);

      const subMetadata = pool.subscribeMany(GEYSERELAY, [
        {
          kinds: [0],
          authors: pubkeys2Fetch,
        },
      ], {
        onevent(event) {
          const parsedMetadata = JSON.parse(event.content);
          setMetadata((current) => ({
            ...current,
            [event.pubkey]: parsedMetadata,
          }));
        },
      });

      return () => {
        subMetadata.close(); // Use close() to unsubscribe
      };
    }
  }, [events, pool]);

  useEffect(() => {
    console.log(events); // Log events whenever they change
  }, [events]);

  const handleInputChange = (e) => {
    setInputTag(e.target.value);
  };

  const renderContent = (content) => {
    // Function to check if a URL is an image
    const isImageUrl = (url) => {
      return /\.(jpeg|jpg|gif|png|svg|bmp|webp)$/.test(url);
    };

    // Function to extract links from content
    const extractLinks = (text) => {
      const urlRegex = /https?:\/\/[^\s]+/g; // Regex to match URLs
      return text.match(urlRegex) || []; // Return an array of found URLs
    };

    const links = extractLinks(content); // Get all links from content

    return (
      <>
        {links.map((url, index) => (
          isImageUrl(url) ? (
            <Image
              key={index}
              src={url}
              alt="Content Image"
              borderRadius="md"
              mt={2}
              boxSize= "300px" // Responsive size
              objectFit="cover" // Ensures the image covers the specified box size
            />
          ) : (
            <Text key={index} mt={2} color="blue.500">
              <a href={url} target="_blank" rel="noopener noreferrer">{url}</a>
            </Text>
          )
        ))}
        {/* Render text content without links */}
        <Text mt={2}>{content.replace(/https?:\/\/[^\s]+/g, '')}</Text>
      </>
    );
  };

  return (
    <Box maxW="800px" mx="auto" p={4} borderWidth="1px" borderRadius="19%" boxShadow="lg" bg="gray.50">
      <VStack spacing={4} align="stretch">
        {events.map((event, index) => {
          const profileData = metadata[event.pubkey] || {}; // Fetch profile data for the current event's pubkey

          return (
            <Box key={index} borderWidth="1px" borderRadius="6%" p={4} boxShadow="md" bg="white">
              <Flex align="center">
                {/* User Profile Image */}
                <Image
                  borderRadius="45%"
                  boxSize="50px"
                  src={profileData.picture || '/default-avatar.png'} // Fallback image
                  alt={profileData.name || 'User Image'}
                  mr={4} // Margin right for spacing
                />
                <Box>
                  <Text fontWeight="bold">{profileData.name || 'Unknown User'}</Text>
                  <Text fontSize="sm" color="gray.500">
                    {new Date(event.created_at * 1000).toLocaleString()} {/* Assuming created_at is in seconds */}
                  </Text>
                </Box>
              </Flex>
              <Divider my={2} />
              {renderContent(event.content)} {/* Render content or image */}
            </Box>
          );
        })}
      </VStack>
    </Box>
  );
};

export default Tattoostr;
