import React from 'react';
import { Box, Container, Grid, Heading, Text } from '@chakra-ui/react';
import ArtistCard from './ArtistCard'; // Import the ArtistCard component
import artistsData from './ArtistsData'; // Import artist data

const Artists = () => {
  return (
    <Container maxW="container.lg" mt="2em" textAlign="center" bg="gray.50" p="2em" borderRadius="md" boxShadow="lg">
      <Box mb="2em">
        <Heading size="lg" mb="0.5em" fontWeight="bold" color="gray.700">Meet Our Artists</Heading>
        <Text fontSize="1.3em" color="gray.600">Get to know our talented artists and their specialties.</Text>
      </Box>
      <Grid templateColumns={{ base: "1fr", sm: "1fr 1fr", md: "repeat(3, 1fr)" }} gap={8}>
        {artistsData.map(artist => (
          <ArtistCard
            key={artist.id}
            name={artist.name}
            specialty={artist.specialty}
            imageUrl={artist.imageUrl} // Pass the imageUrl prop
            socials={artist.socials}    // Pass the socials prop
          />
        ))}
      </Grid>
    </Container>
  );
}

export default Artists;
