import React from 'react';
import { Box, Container, Flex, Image, Heading, IconButton } from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';

const Header = () => {
  return (
    <Box bg="#332C2C" color="white" py={{ base: '1em', md: '1.5em' }} px="1em">
      <Container maxW="container.xl">
        <Flex
          justify="space-between"
          align="center"
          direction={{ base: 'column', md: 'row' }}
        >
          <Flex align="center" direction="row" mb={{ base: '1em', md: '0' }}>
            {/* Logo with hover and transition effect */}
            <Image
              src="/mantra.png"
              alt="Mantra Logo"
              w="3.5em"
              h="3.5em"
              borderRadius="50px"
              transition="transform 0.3s ease"
              _hover={{ transform: 'scale(1.1)' }}
            />
            <Heading
              size={{ base: 'lg', md: 'md' }}
              ml="1.5em"
              transition="color 0.3s ease"
              _hover={{ color: 'gray.400' }}
            >
              MANTRA TATTOO STUDIO
            </Heading>
          </Flex>
          
        </Flex>
      </Container>
    </Box>
  );
};

export default Header;
