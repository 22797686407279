import React from 'react';
import './Modal.css'; // Create a CSS file for modal styling

const Modal = ({ isOpen, onClose, qrCodeDataUrl, onCopy }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h3>Scan the QR code to pay:</h3>
        {qrCodeDataUrl && <img src={qrCodeDataUrl} alt="QR Code" />}
        <button className="modal-copy" onClick={onCopy}>Copy Invoice to Clipboard</button>
        <button className="modal-cancel" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default Modal;
