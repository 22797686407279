import React, { useState } from 'react';
import { Tabs, TabList, Tab, TabPanels, TabPanel, Box, Button } from '@chakra-ui/react';
import Tattoos from './Tattoos';
import Piercings from './Piercings';

const Services = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleTabChange = index => {
    setSelectedTabIndex(index);
  };

  // Array of service names for the tabs
  const serviceNames = ['Tattoos', 'Piercings'];

  return (
    <Box bg="black" color="white" textAlign="center" py={10}>
      <Tabs index={selectedTabIndex} onChange={handleTabChange} variant="unstyled">
        <TabList justifyContent="center" w="100%" mb={8}>
          {/* Dynamically render tabs */}
          {serviceNames.map((serviceName, index) => (
            <Tab
              key={index}
              ml={index !== 0 ? 8 : 0}
              borderRadius="3px"
              _selected={{ color: 'teal.500', bg: 'gray.800' }}
              _hover={{ bg: 'gray.700', color: 'teal.300' }}
              px={6}
              py={2}
              transition="all 0.3s ease"
            >
              {serviceName}
            </Tab>
          ))}
        </TabList>

        <TabPanels>
          {/* Render TabPanels for each service */}
          <TabPanel>
            <Tattoos />
          </TabPanel>
          <TabPanel>
            <Piercings />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default Services;
