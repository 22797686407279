// ArtistData.js
const artistsData = [
  {
    id: 1,
    name: 'Stacy Blu',
    specialty: 'Tattoo, Piercing and Interior Design',
    imageUrl: 'assets/images/artists/1stacyblu.jpeg',
    socials: {
      phone: '+254797332515'
    },
    wallet: {
      bitcoin: 'bc1qexample123stacyblu',      // Bitcoin wallet address
      lightning: 'lnbc1qexamplelud16stacyblu' // Lightning wallet address
    }
  },
  {
    id: 2,
    name: 'Turiz',
    specialty: 'Tattoo, Graphics',
    imageUrl: 'assets/images/artists/1turiz.jpeg',
    socials: {
      phone: '+254715659527'
    },
    wallet: {
      bitcoin: 'bc1qexample123turiz',         // Bitcoin wallet address
      lightning: 'lnbc1qexamplelud16turiz'    // Lightning wallet address
    }
  },
  {
    id: 3,
    name: 'Shyrah',
    specialty: 'Tattoo',
    imageUrl: 'assets/images/artists/1shyrah.jpeg',
    socials: {
      phone: '+254758302725'
    },
    wallet: {
      bitcoin: 'bc1qexample123shyrah',        // Bitcoin wallet address
      lightning: 'lnbc1qexamplelud16shyrah'   // Lightning wallet address
    }
  },
  {
    id: 4,
    name: 'Joan Reyya',
    specialty: 'Make-up Artist',
    imageUrl: 'assets/images/artists/joan.jpeg',
    socials: {
      phone: '+254710288273'
    },
    wallet: {
      bitcoin: 'bc1qexample123shyrah',        // Bitcoin wallet address
      lightning: 'lnbc1qexamplelud16shyrah'   // Lightning wallet address
    }
  },

  // Add more artists as needed
];

export default artistsData;
