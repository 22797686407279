import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Box, Text, Button, chakra } from '@chakra-ui/react';
import { shouldForwardProp } from '@chakra-ui/system';
import heroImage from '../../assets/bgimages/inking2.jpg';
import Artists from '../artists/Artists'; // Import the Artists component
import MapContainer from '../location/Map'; // Import the MapContainer component
import ContactForm from '../common/ContactForm'; // Import the ContactForm component
import BuyCoffee from '../donate/LNURL/BuyCoffee'; // Import the BuyCoffee component

// ChakraMotionBox with motion properties
const ChakraMotionBox = chakra(motion.div, {
  shouldForwardProp: shouldForwardProp, // Pass shouldForwardProp from @chakra-ui/system
});

const LandingPage = () => {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  // Button animation variants
  const buttonVariants = {
    rest: { scale: 1 },
    hover: { scale: 1.1 },
  };

  // Handler to open the contact modal
  const handleBookNowClick = () => {
    setIsContactModalOpen(true);
  };

  return (
    <Box
      className="relative min-h-screen bg-cover bg-center flex"
      backgroundImage={`url(${heroImage})`}
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      fontFamily="Helvetica, Arial, sans-serif" // Set global font family
    >
      <Box className="flex-1 flex flex-col justify-center">
        <ChakraMotionBox
          initial={{ opacity: 0, y: 2 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 3.7, ease: "easeOut" }}
          fontSize="2.3em"
          color="#FDC045"
          mb="1.2em"
          textAlign="center"
          fontFamily="'Helvetica', sans-serif" // Customize font for hero text
        >
          😎 EXPRESS YOURSELF WITH OUR CUSTOM TATTOOS AND EXPERT PIERCINGS 💉
          <Box className="flex mt-4" justifyContent="center" alignItems="center">
            <Text>
              😊 At Mantra Tattoo Studio, we believe in turning your body into a canvas for self-expression. 🎨
            </Text>
          </Box>
          <Box className="flex justify-center items-center mt-4">
            <motion.div whileHover="hover" variants={buttonVariants}>
              <Button
                backgroundColor="#FDC045"
                color="white"
                fontSize="1em"
                padding="0.75em 1.5em"
                borderRadius="21%"
                _hover={{ bg: "#ffc107", borderColor: "#ffc107" }}
                onClick={handleBookNowClick} // Open the contact modal on click
              >
                Book Now
              </Button>
            </motion.div>
          </Box>
          <Box className="flex mt-4" justifyContent="center" alignItems="center">
            <Text color="#FeCa0e">
              🎨 Our team of talented artists specializes in creating stunning tattoos that reflect your unique style and personality. 💖 With years of experience and a passion for the art of tattooing, we are dedicated to providing our clients with top-quality work in a safe and welcoming environment. 🌟
            </Text>
          </Box>
        </ChakraMotionBox>
      </Box>

      {/* Artists Section */}
      <Box bg="white" boxShadow="lg" p={8}>
        <Artists />
      </Box>

      {/* Map Section */}
      <Box bg="white" boxShadow="lg" p={8} mt={4}>
        <MapContainer />
      </Box>

      {/* Buy Coffee Section */}
      <Box bg="white" boxShadow="lg" p={8} mt={4}>
        <BuyCoffee />
      </Box>

      {/* Contact Form Modal */}
      <ContactForm isOpen={isContactModalOpen} onClose={() => setIsContactModalOpen(false)} />
    </Box>
  );
};

export default LandingPage;
